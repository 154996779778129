import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SurveyButton from "../../components/common/SurveyButton";
import { PageContext } from "../../store/context/page-context";

const SurveyButtonContainer = ({ isMod }) => {
  const [show, setShow] = useState(true);
  const { handlerOpenQuestionnaire } = useContext(PageContext);

  const buttonClick = () => {
    setShow(false);
    handlerOpenQuestionnaire(true);
  };
  useEffect(() => {
    show && setShow(localStorage.getItem("questionnaire") !== "closed");
  }, [show]);

  if (!show) return null;

  return <SurveyButton buttonClick={buttonClick} isMod={isMod} />;
};

SurveyButtonContainer.propTypes = {
  isMod: PropTypes.bool,
};
export default SurveyButtonContainer;
