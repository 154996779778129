import React, { useRef, useContext } from "react";
import { MediaAuthContext } from "../store/context/media-auth-context";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import { PAGES } from "../helpers/constants";
import PG_PD_RATING_REVIEW_CONTENT from "../components/product-database/RatingReview/index.content";
import HomeIntro from "../components/product-database/HomeIntro";
import SearchStructure from "../components/product-database/SearchStructure";
import Database from "../components/product-database/Database";
import ChromeRating from "../components/product-database/ChromeRating";
import PartnersBrand from "../components/product-database/PartnersBrand";
import RatingReview from "../components/product-database/RatingReview";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import Possibility from "../components/product-database/Possibility";
import StandardFormBlockContainer from "../containers/common/forms/StandardFormBlockContainer";
import "../components/product-database/index.scss";

const PgPD = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const pricingRef = useRef(null);
  const { postData } = PG_PD_RATING_REVIEW_CONTENT;

  return (
    <Layout tariffsElement={pricingRef} customClass="PgPD">
      <Seo
        title="Amazon Product Database by AMZScout"
        description="AMZScout Product Database - explore new product ideas to sell on Amazon. Filter by categories, sales, revenue, and other parameters to find products with the highest potential."
        page="product-database"
        manifest="browserconfig.xml"
        ogImg=""
      />
      <HomeIntro />
      <ChromeRating />
      <PartnersBrand />
      <RatingReview
        excellentText="Excellent"
        posts={postData}
      />
      <Possibility />
      <StandardFormBlockContainer
        buttonTextFirstState="Try Product Database For Free"
        buttonTextSecondState="Try Product Database For Free"
        page={PAGES.PD}
        customClass={
          isAuth
            ? "PgPD-standardFormPageBlock-secondState"
            : "PgPD-standardFormPageBlock-centralForm"
        }
        title="Choose a low-risk product on Amazon with a Product Database FREE TRIAL!"
      />
      <CustomizableContainer pricingRef={pricingRef} subTitlePro />
      <Database />
      <SearchStructure />
      <StandardFormBlockContainer
        buttonTextFirstState="Try Product Database For Free"
        buttonTextSecondState="Try Product Database For Free"
        page={PAGES.PD}
        customClass={
          isAuth
            ? "PgPD-standardFormPageBlock-secondState"
            : "PgPD-standardFormPageBlock-lastForm"
        }
        title="Discover products that will bring a profit in the first month with a Product Database FREE TRIAL!"
      />
    </Layout>
  );
};

export default PgPD;
