export const ERRORS = {
  URL_ERRORS: {
    ERROR_PARSING_URL_PARAMETERS: "ERROR PARSING URL PARAMETERS:",
  },
  ANALYTICS_ERRORS: {
    ERROR_SENDING_EVENT_TO_ANALYTICS: "ERROR SENDING EVENT TO ANALYTICS:",
  },
  IMAGE_ERRORS: {
    UNDEFINED_IMAGE: "UNDEFINED_IMAGE",
  },
};
