import React from "react";
import PG_PD_POSSIBILITY_CONTENT from "./index.content";
import Screenshot from "../Screenshot";
import ListWithTitle from "../ListWithTitle";
import "./index.scss";

const Possibility = () => {
  const { search, readyMadeKits, prospect } = PG_PD_POSSIBILITY_CONTENT;

  return (
    <section className="PgPD-Possibility">
      <div className="container">
        <h2 className="PgPD-Possibility__title">
          AMZScout Product Database has the best opportunities to find top items
          on 10 Amazon marketplaces
        </h2>
        <div className="PgPD-Possibility-row PgPD-Possibility-rowOne">
          <ListWithTitle
            list={search.paragraphText}
            classes="PgPD-Possibility-search"
            title={search.title}
          />
          <Screenshot
            classes="PgPD-Possibility-rowOne__screenshot"
            listPictures={search.screenshot}
            withoutWebp
          />
        </div>
        <div className="PgPD-Possibility__line" />
        <div className="PgPD-Possibility-row PgPD-Possibility-rowTwo">
          <div className="PgPD-Possibility__wrapperPictures">
            <img
              className="PgPD-Possibility-rowTwo__productSelection"
              src={readyMadeKits.productSelection}
              alt="img"
              loading="lazy"
            />
            <img
              className="PgPD-Possibility-rowTwo__selectionPanel"
              src={readyMadeKits.selectionPanel}
              alt="img"
              loading="lazy"
            />
            <img
              className="PgPD-Possibility-rowTwo__newProducts"
              src={readyMadeKits.newProducts}
              alt="img"
              loading="lazy"
            />
            <img
              className="PgPD-Possibility-rowTwo__trendingProducts"
              src={readyMadeKits.trendingProducts}
              alt="img"
              loading="lazy"
            />
            <img
              className="PgPD-Possibility-rowTwo__firstDescription"
              src={readyMadeKits.firstDescription}
              alt="img"
              loading="lazy"
            />
            <img
              className="PgPD-Possibility-rowTwo__secondDescription"
              src={readyMadeKits.secondDescription}
              alt="img"
              loading="lazy"
            />
          </div>
          <ListWithTitle
            list={readyMadeKits.paragraphText}
            classes="PgPD-Possibility-readyMadeKits"
            title={readyMadeKits.title}
          />
        </div>
        <div className="PgPD-Possibility__line" />
        <div className="PgPD-Possibility-row PgPD-Possibility-rowThree">
          <ListWithTitle
            list={prospect.paragraphText}
            classes="PgPD-Possibility-prospect"
            title={prospect.title}
          />
          <div className="PgPD-Possibility__wrapperPictures">
            <Screenshot
              classes="PgPD-Possibility-rowThree__firstScreenshot"
              listPictures={prospect.firstScreenshot}
              withoutWebp
            />
            <picture>
              <source
                media="(min-width: 992px)"
                srcSet={`${prospect.secondScreenshot.deskImgPng} 1x, ${prospect.secondScreenshot.deskImgPng2x} 2x`}
              />
              <source
                media="(min-width: 768px) and (max-width: 991px)"
                srcSet={`${prospect.secondScreenshot.midImgPng} 1x, ${prospect.secondScreenshot.midImgPng2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${prospect.secondScreenshot.mobImgPng} 1x, ${prospect.secondScreenshot.mobImgPng2x} 2x`}
              />
              <img
                src={prospect.secondScreenshot.deskImgPng}
                className="PgPD-Possibility-rowThree__secondScreenshot"
                alt="img"
                loading="lazy"
              />
            </picture>
            <img
              className="PgPD-Possibility-rowThree__panel"
              src={prospect.panel}
              alt="img"
              loading="lazy"
            />
            <img
              className="PgPD-Possibility-rowThree__logo"
              src={prospect.logo}
              alt="img"
              loading="lazy"
            />
            <div className="PgPD-Possibility-rowThree__alibaba">
              Find Product on Alibaba.com
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Possibility;
