import React from "react"

const PG_PD_HOME_INTRO_CONTENT = {
  itemList: [
    "Select products using 16 filters, like revenue, rank, or listing quality",
    "Easily discover high-potential products to sell with ready-to-go filters",
    "Estimate sales, and keep track of prices and costs of competitors",
  ],
}

export default PG_PD_HOME_INTRO_CONTENT
