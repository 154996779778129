import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { ERRORS } from "../../helpers/errors";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

const FULL_IMAGE_DATA = [
  { suffix: "@mob", media: "(max-width: 767px)" },
  { suffix: "@mid", media: "(min-width: 768px) and (max-width: 991px)" },
  { suffix: "@smallDesk", media: "(min-width: 992px) and (max-width: 1199px)" },
  { suffix: "@midDesk", media: "(min-width: 1200px) and (max-width: 1365px)" },
  { suffix: "", media: "(min-width: 1366px)" },
];

const CURRENT_IMAGE_DATA = [
  { suffix: "@mob", media: "(max-width: 767px)" },
  { suffix: "@mid", media: "(min-width: 768px) and (max-width: 1199px)" },
];

const FastImage = (props) => {
  const {
    defaultName,
    imageData,
    imageClassName,
    imageAltName,
    isAdaptive,
    isWithRatio,
    isFullBreaks,
    wrapImageClassName,
  } = props;
  const getImageFromQuery = useCallback(
    (name) => {
      let ratio = "";
      if (typeof window !== "undefined") {
        ratio = window.devicePixelRatio > 1 ? "2x" : "";
      }
      const fileName = isWithRatio
        ? `${name}${!name.includes("@") && ratio ? "@" : ""}${ratio}`
        : name;
      const filter = imageData.allFile.edges.filter(
        (e) => e.node.name === fileName
      );

      if (filter.length > 0)
        return filter[0].node.childImageSharp.gatsbyImageData;
      throw new Error(`${ERRORS.IMAGE_ERRORS.UNDEFINED_IMAGE}: ${fileName}`);
    },
    [imageData, isWithRatio]
  );

  const getImageFromName = useCallback(
    (name) => getImage(getImageFromQuery(name)),
    [getImageFromQuery]
  );

  const retrieveImage = useCallback(() => {
    const data = isFullBreaks ? FULL_IMAGE_DATA : CURRENT_IMAGE_DATA;
    const arr = data.map(({ suffix, media }) => ({
      media,
      image: getImageFromName(suffix ? `${defaultName}${suffix}` : defaultName),
    }));
    return withArtDirection(getImageFromName(defaultName), arr);
  }, [defaultName, isFullBreaks, getImageFromName]);

  const currentImage = useMemo(() => {
    return isAdaptive ? retrieveImage() : getImageFromName(defaultName);
  }, [defaultName, isAdaptive, isFullBreaks]);

  return (
    <GatsbyImage
      image={currentImage}
      imgClassName={imageClassName}
      className={wrapImageClassName}
      alt={imageAltName}
    />
  );
};

FastImage.propTypes = {
  imageData: PropTypes.object.isRequired,
  imageClassName: PropTypes.string,
  imageAltName: PropTypes.string,
  isAdaptive: PropTypes.bool,
  isWithRatio: PropTypes.bool,
  defaultName: PropTypes.string.isRequired,
};

export default FastImage;
