import React from "react";
import PG_PD_DATABASE_CONTENT from "./index.content";
import Screenshot from "../Screenshot";
import "./index.scss";

const Database = () => {
  const { listPictures, arrowSvg, imgSvg } = PG_PD_DATABASE_CONTENT;

  return (
    <section className="PgPD-Database">
      <div className="container">
        <div className="PgPD-Database__wrap">
          <div className="PgPD-Database__info">
            <p className="PgPD-Database__text">
              A Product Database is the most suitable alternative to old
              techniques, like endlessly scrolling through Amazon and filling
              excel spreadsheets with low-potential products while manually
              entering datasets like ASIN, weight, sales volume, etc.
            </p>
            <p className="PgPD-Database__text">
              <b>
                There’s no need to download tons of information and import it to
                .xls or other software to compare - you only need to log in and
                get access to the Product Database.
              </b>
            </p>
          </div>
          <div className="PgPD-Database__imageWrapper">
            <img className="PgPD-Database__img" src={imgSvg} alt="img" />
            <img className="PgPD-Database__arrow" src={arrowSvg} alt="img" />
            <Screenshot
              classes="PgPD-Database__screenshot"
              listPictures={listPictures}
              withoutWebp
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Database;
