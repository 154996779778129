import React from "react";
import TrustPilotIcon from "../../../assets/images/vector/components/RatingReview/trustpilot_logo.svg";

const Trust = () => {
  return (
    <div className="PgPD-RatingReview-trust">
      <img src={TrustPilotIcon} alt="" />
      <div className="PgPD-RatingReview-trust-value">
        <span>‘</span>
        Excellent
        <span>’</span>
      </div>
      <div className="PgPD-RatingReview-trust-subTitle">
        Based on Trustpilot reviews
      </div>
    </div>
  );
};

export default Trust;
