import React from "react"

const PG_PD_RATING_REVIEW_CONTENT = {
  postData: [
    {
      title: "Amazing and very user-friendly…",
      description: () =>
        "Amazing and very user-friendly tool. Effective and productive to your small business. I love it and want to scout the database forever. Thanks, AMZScout.",
    },
    {
      title: "This is great and helpful…",
      description: () =>
        "This is great and helpful. I like the Amazon Product Database very much. As I am an Amazon seller, I have to check the sales trends online every day, so we use them often. It brings us much help and saves time during our work.",
    },
    {
      title: "AMZScout is a useful tool…",
      description: () =>
        "AMZScout is a useful tool for Amazon sellers to get estimated sales, a helpful reference to find the right product to sell. Also, the LQS data is good for me to improve my listing.",
    },
  ],
}

export default PG_PD_RATING_REVIEW_CONTENT
