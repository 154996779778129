import React from "react"

import deskImgOneWebp2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-1@2x.webp"
import deskImgOneWebp from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-1.webp"
import deskImgOnePng2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-1@2x.png"
import deskImgOnePng from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-1.png"
import mobImgOneWebp2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-1@mob2x.webp"
import mobImgOneWebp from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-1@mob.webp"
import mobImgOnePng2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-1@mob2x.png"
import mobImgOnePng from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-1@mob.png"

import productSelectionSvg from "../../../assets/images/vector/pages/product-database/Possibility/PDPossibilityView_productSelection.svg"
import selectionPanelSvg from "../../../assets/images/vector/pages/product-database/Possibility/PDPossibilityView_selectionPanel.svg"
import newProductsSvg from "../../../assets/images/vector/pages/product-database/Possibility/PDPossibilityView_newProducts.svg"
import trendingProductsSvg from "../../../assets/images/vector/pages/product-database/Possibility/PDPossibilityView_trendingProducts.svg"
import firstDescriptionSvg from "../../../assets/images/vector/pages/product-database/Possibility/PDPossibilityView_firstDescription.svg"
import secondDescription from "../../../assets/images/vector/pages/product-database/Possibility/PDPossibilityView_secondDescription.svg"

import panelSvg from "../../../assets/images/vector/pages/product-database/Possibility/PDPossibilityView-panel.svg"
import logoPng from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_logo.png"

import deskImgTwoWebp2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-2@2x.webp"
import deskImgTwoWebp from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-2.webp"
import deskImgTwoPng2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-2@2x.png"
import deskImgTwoPng from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-2.png"
import mobImgTwoWebp2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-2@mob2x.webp"
import mobImgTwoWebp from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-2@mob.webp"
import mobImgTwoPng2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-2@mob2x.png"
import mobImgTwoPng from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-2@mob.png"

import deskImgThreeWebp2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-3@2x.webp"
import deskImgThreeWebp from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-3.webp"
import deskImgThreePng2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-3@2x.png"
import deskImgThreePng from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-3.png"
import midImgThreePng2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-3@mid2x.png"
import midImgThreePng from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-3@mid.png"
import mobImgThreeWebp2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-3@mob2x.webp"
import mobImgThreeWebp from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-3@mob.webp"
import mobImgThreePng2x from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-3@mob2x.png"
import mobImgThreePng from "../../../assets/images/raster/pages/product-database/Possibility/PDPossibilityView_screenshot-3@mob.png"

const PG_PD_POSSIBILITY_CONTENT = {
  search: {
    title:
      "1. Customize a search by 16 parameters according to precise criteria",
    paragraphText: [
      "Category",
      "Keywords",
      "Price",
      "Reviews",
      "Weight",
      "Estimated Sales",
      "Product Tier",
      "Rank",
      "Estimated Revenue",
      "Rating",
      "Listing Quality",
      "and more",
    ],
    screenshot: {
      deskImgWebp2x: deskImgOneWebp2x,
      deskImgWebp: deskImgOneWebp,
      deskImgPng2x: deskImgOnePng2x,
      deskImgPng: deskImgOnePng,
      mobImgWebp2x: mobImgOneWebp2x,
      mobImgWebp: mobImgOneWebp,
      mobImgPng2x: mobImgOnePng2x,
      mobImgPng: mobImgOnePng,
    },
  },
  readyMadeKits: {
    title:
      "2. Use ready-to-go selections of products with pre-packaged filters",
    paragraphText: [
      "TOP 1,000 best-selling products in any category",
      "Products to start selling with a low initial cost",
      "Most profitable products with extremely high margins",
      "New and trending products with growing demand and low competition",
      "Products that are easy to launch for your business",
    ],
    productSelection: productSelectionSvg,
    selectionPanel: selectionPanelSvg,
    newProducts: newProductsSvg,
    trendingProducts: trendingProductsSvg,
    firstDescription: firstDescriptionSvg,
    secondDescription: secondDescription,
  },
  prospect: {
    title: "3. Check the prospect of investing in a product on Amazon",
    paragraphText: [
      "Use product history to analyze rank and price over time since product launch",
      "Calculate all fees, including FBA, to predict profits and expenses ",
      "Find the direct supplier of any product in question on Alibaba in one click",
    ],
    panel: panelSvg,
    logo: logoPng,
    firstScreenshot: {
      deskImgWebp2x: deskImgTwoWebp2x,
      deskImgWebp: deskImgTwoWebp,
      deskImgPng2x: deskImgTwoPng2x,
      deskImgPng: deskImgTwoPng,
      mobImgWebp2x: mobImgTwoWebp2x,
      mobImgWebp: mobImgTwoWebp,
      mobImgPng2x: mobImgTwoPng2x,
      mobImgPng: mobImgTwoPng,
    },
    secondScreenshot: {
      deskImgWebp2x: deskImgThreeWebp2x,
      deskImgWebp: deskImgThreeWebp,
      deskImgPng2x: deskImgThreePng2x,
      deskImgPng: deskImgThreePng,
      midImgPng2x: midImgThreePng2x,
      midImgPng: midImgThreePng,
      mobImgWebp2x: mobImgThreeWebp2x,
      mobImgWebp: mobImgThreeWebp,
      mobImgPng2x: mobImgThreePng2x,
      mobImgPng: mobImgThreePng,
    },
  },
}

export default PG_PD_POSSIBILITY_CONTENT
