import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const SurveyButton = ({ buttonClick, isMod }) => {
  return (
    <button
      className={getTrueClasses("GC-SB", isMod && "GC-SB_mod")}
      onClick={buttonClick}
    >
      <span className="GC-SB__start">Take a</span>
      <span className="GC-SB__center">Survey</span>
      <span className="GC-SB__end">
        to Choose the Right Seller's Course for You
      </span>
      <span className="GC-SB__flicker" />
    </button>
  );
};

SurveyButton.propTypes = {
  buttonClick: PropTypes.func.isRequired,
};

export default SurveyButton;
