import React, { useContext } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import { PAGES } from "../../../helpers/constants";
import { getTrueClasses } from "../../../helpers";
import { graphql, useStaticQuery } from "gatsby";
import PG_PD_HOME_INTRO_CONTENT from "./index.content";
import StandardFormBlockContainer from "../../../containers/common/forms/StandardFormBlockContainer";
import FastImage from "../../../containers/common/FastImage";
import "./index.scss";

const HomeIntro = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const { itemList } = PG_PD_HOME_INTRO_CONTENT;

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          name: { regex: "/PDHomeIntroView-screen/" }
          ext: { eq: ".png" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                blurredOptions: { toFormat: AUTO }
                jpgOptions: { progressive: false }
              )
            }
            name
          }
        }
      }
    }
  `);

  return (
    <section
      className={getTrueClasses(
        "PgPD-HomeIntro",
        isAuth && "PgPD-HomeIntro-secondState"
      )}
    >
      <div className="container">
        <div className="PgPD-HomeIntro-row">
          <div className="PgPD-HomeIntro__info">
            <h1 className="PgPD-HomeIntro__title">AMZScout Product Database</h1>
            <p className="PgPD-HomeIntro__subTitle">
              Find the Potential Bestseller{" "}
              <span>Out of 600 Million Amazon Items!</span>
            </p>
            <ul className="PgPD-HomeIntro__points PgPD-HomeIntro-points">
              {itemList.map((text, i) => (
                <li key={i} className="PgPD-HomeIntro-points__item">
                  <img src={PointIcon} alt="" />
                  <p>{text}</p>
                </li>
              ))}
            </ul>
            <StandardFormBlockContainer
              buttonTextFirstState="Try Product Database For Free"
              buttonTextSecondState="Try Product database For Free!"
              page={PAGES.PD}
              customClass="PgPD-HomeIntro-emailCollect"
              placeholder="Enter your email to start your FREE TRIAL!"
              isIntro
            />
          </div>
          <div className="PgPD-HomeIntro-screen">
            <FastImage
              imageData={data}
              wrapImageClassName="PgPD-HomeIntro__img"
              imageAltName="home image"
              isAdaptive
              isWithRatio
              defaultName="PDHomeIntroView-screen"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

HomeIntro.propTypes = {
  isLoading: PropTypes.bool,
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
};

export default HomeIntro;
