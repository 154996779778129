import React from "react";
import PG_PD_SEARCH_STRUCTURE_CONTENT from "./index.content";
import "./index.scss";

const SearchStructure = () => {
  const { itemList } = PG_PD_SEARCH_STRUCTURE_CONTENT;

  return (
    <section className="PgPD-SearchStructure">
      <div className="container">
        <h2 className="PgPD-SearchStructure__title">
          How is a search structure created by a Product Database?
        </h2>
        <ul className="PgPD-SearchStructure__wrap">
          {itemList.map((text, i) => (
            <li key={i} className="PgPD-SearchStructure__item">
              <div className="PgPD-SearchStructure__marker" />
              <p className="PgPD-SearchStructure__text">{text}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SearchStructure;
