import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import { LayoutContext } from "../../../../store/context/layout-context";
import { POPUP_TYPES } from "../../../../helpers/constants";
import PropTypes from "prop-types";
import { targetStatus } from "../../../../helpers/browser";
import "./index.scss";

const SecondStateButton = (props) => {
  const {
    redirectUrl,
    buttonTextSecondState,
    redirectUrlSecondStateButton,
    showMobileProPopup,
  } = props;
  const {
    media: { isNotInitMobile },
  } = useContext(MediaAuthContext);
  const { popupState, openPopup } = useContext(LayoutContext);
  const url = redirectUrlSecondStateButton || redirectUrl;
  const clickHandler = (e) => {
    e.preventDefault();
    if (showMobileProPopup && isNotInitMobile && !popupState.open) {
      openPopup(POPUP_TYPES.MOBILE_PRO);
    } else {
      window.open(url, targetStatus() ? "_blank" : "_self");
    }
  };
  return (
    <a
      href={url}
      target={targetStatus() ? "_blank" : "_self"}
      className="StandardFormIntroBlock-secondStateButton"
      rel="nofollow"
      onClick={clickHandler}
    >
      {buttonTextSecondState}
    </a>
  );
};

SecondStateButton.propTypes = {
  redirectUrl: PropTypes.string,
  redirectUrlSecondStateButton: PropTypes.string,
  buttonTextSecondState: PropTypes.string.isRequired,
};

export default SecondStateButton;
