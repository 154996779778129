import React from "react";
import PropTypes from "prop-types";

const ListWithTitle = ({ list, classes, title }) => (
  <div className={classes}>
    <h3 className={`${classes}__title`}>{title}</h3>
    <ul className={`${classes}-list`}>
      {list.map((text, i) => (
        <li key={i} className={`${classes}-list__item`}>
          <div className={`${classes}-list__marker`} />
          <p>{text}</p>
        </li>
      ))}
    </ul>
  </div>
);

ListWithTitle.propTypes = {
  list: PropTypes.array.isRequired,
  classes: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ListWithTitle;
