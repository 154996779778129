import React from "react"

const PG_PD_SEARCH_STRUCTURE_CONTENT = {
  itemList: [
    "Use up to 16 filters to find the product that works best for your needs",
    "Make sure the products are cheap to ship to maximize your margins and make more money per item sold",
    "Exclude certain keywords to narrow down your search even further",
  ],
}

export default PG_PD_SEARCH_STRUCTURE_CONTENT
