import React from "react"
import deskImgWebp2x from "../../../assets/images/raster/pages/product-database/Database/PDDatabaseView_screenshot@2x.webp"
import deskImgWebp from "../../../assets/images/raster/pages/product-database/Database/PDDatabaseView_screenshot.webp"
import deskImgPng2x from "../../../assets/images/raster/pages/product-database/Database/PDDatabaseView_screenshot@2x.png"
import deskImgPng from "../../../assets/images/raster/pages/product-database/Database/PDDatabaseView_screenshot.png"

import mobImgWebp2x from "../../../assets/images/raster/pages/product-database/Database/PDDatabaseView_screenshot@mob2x.webp"
import mobImgWebp from "../../../assets/images/raster/pages/product-database/Database/PDDatabaseView_screenshot@mob.webp"
import mobImgPng2x from "../../../assets/images/raster/pages/product-database/Database/PDDatabaseView_screenshot@mob2x.png"
import mobImgPng from "../../../assets/images/raster/pages/product-database/Database/PDDatabaseView_screenshot@mob.png"

import ImgSvg from "../../../assets/images/vector/pages/product-database/Database/PDDatabaseView_1.svg"
import ArrowSvg from "../../../assets/images/vector/pages/product-database/Database/PDDatabaseView_arrow.svg"

const PG_PD_DATABASE_CONTENT = {
  listPictures: {
    deskImgWebp2x,
    deskImgWebp,
    deskImgPng2x,
    deskImgPng,
    mobImgWebp2x,
    mobImgWebp,
    mobImgPng2x,
    mobImgPng,
  },
  imgSvg: ImgSvg,
  arrowSvg: ArrowSvg,
}

export default PG_PD_DATABASE_CONTENT
